import { useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { ClickAwayListener } from "@mui/material";
import { LargeDivider } from "../styled/forms/dividers";
import {
  AmountBox,
  DropDownBox,
  FullButton,
  MiniAmountBox,
  MiniAmountBoxFull,
  TextBox,
  ToolTipContainer,
} from "../styled/input/Input";

import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../styled/loaders/CircularLoader";
import Toast from "../hooks/Toast";
import { formatterZero, toFixedIfNecessary } from "../utils/utils";

const ManageStakingModal = ({ open, details }) => {
  const { manageStake, setManageStake } = open;

  const { user, rewards, amount, ref, asset, roi } = details;

  const [setAmount, setSetAmount] = useState(details.amount);
  function handleAmount(e) {
    const { value } = e.target;

    if (value) {
      setSetAmount(Number(value));
    } else {
      setSetAmount(details.amount);
    }
  }

  const [duration, setDuration] = useState(details.duration);
  function handleDuration(e) {
    const { value } = e.target;

    if (value) {
      setDuration(Number(value));
    } else {
      setDuration(details.duration);
    }
  }

  const [numDaysLeft, setNumDaysLeft] = useState(details.daysLeft);
  function handleDaysLeft(e) {
    const { value } = e.target;

    if (value) {
      setNumDaysLeft(Number(value));
    } else {
      setNumDaysLeft(details.daysLeft);
    }
  }

  const [status, setStatus] = useState(details.status);
  const statusRef = useRef();

  function handleStatus(e) {
    const { value } = e.target;

    if (value) {
      setStatus(value);
    } else {
      setStatus(details.status);
    }
  }

  //   const asset = "BTC";
  //   const { asset, minimum, maximum, duration, roi } = pool;

  //   const [cryptoAccount, setCryptoAccount] = useState({});
  //   const [balance, setBalance] = useState(undefined);

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  //   const { id } = user;

  //   useEffect(() => {
  //     if (prices && accounts) {
  //       const live = accounts.live;
  //       if (live) {
  //         setCryptoAccount(live.Crypto);
  //         setBalance(live.Crypto[asset]?.value);
  //       }
  //     }
  //   }, [prices, accounts]);

  // amount
  const amountRef = useRef();
  //   const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);

  const [showToolTip, setShowToolTip] = useState(false);
  const [tooltipMessage, setToolTipMessage] = useState(
    "Using negative sign will reduce from the current rewards, not using any sign will add to the rewards. Please do not remove more than the current amount"
  );

  const [computedPnl, setComputedPnl] = useState(0);

  function handlePnl(e) {
    const { value } = e.target;

    if (value) {
      setComputedPnl(Number(value));
    } else {
      setComputedPnl(0);
    }
  }

  // roi & compiled
  const [compiledTotal, setCompiledTotal] = useState(0);
  const [showCompiledToolTip, setShowCompiledToolTip] = useState(false);
  const compiledToolTipMessage = useState(
    `This is the amount the user will earn after the staking is complete (plus their initial stake)`
  );

  const [isSaving, setIsSaving] = useState(false);
  function handleSave() {
    setIsSaving(true);
    saveStaking();
  }

  async function saveStaking() {
    const q = doc(db, "stakings", ref);
    try {
      await updateDoc(q, {
        amount: Number(setAmount),
        duration,
        daysLeft: Number(numDaysLeft),
        status,
        rewards: increment(Number(computedPnl)),
      });
      setIsSaving(false);
      setToastType("success");
      setToastMessage("Saved successfully");
      setOpenToast(true);

      setTimeout(() => {
        setManageStake(false);
      }, 700);
    } catch (error) {
      console.log("error", error);
      setIsSaving(false);
      setToastType("error");
      setToastMessage("Failed to save. Please try again later.");
      setOpenToast(true);
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={manageStake}
        onClose={() => setManageStake(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Manage staking</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setManageStake(!manageStake)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <LargeDivider className="variant">
                <MiniAmountBoxFull
                  className={
                    amountError ? "amount_box error" : "amount_box variant"
                  }
                >
                  <div className="label">
                    <p>Stake amount</p>
                    {/* <img
                      src="./assets/misc/info.svg"
                      alt=""
                      className="error_inform"
                      id="popcorn"
                      style={{ display: "block" }}
                      onClick={() => setShowToolTip(!showToolTip)}
                    />
                    {showToolTip && (
                      <ClickAwayListener
                        onClickAway={() => setShowToolTip(false)}
                      >
                        <div className="tooltip" id="tooltip">
                          {tooltipMessage}
                        </div>
                      </ClickAwayListener>
                    )} */}
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={amount}
                      // disabled
                      onChange={handleAmount}
                      ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <p>{asset}</p>
                      </span>
                    </span>
                  </div>
                </MiniAmountBoxFull>

                <MiniAmountBoxFull
                  className={
                    amountError ? "amount_box error" : "amount_box variant"
                  }
                >
                  <div className="label">
                    <p>Rewards</p>
                    <img
                      src="./assets/misc/info.svg"
                      alt=""
                      className="error_inform"
                      id="popcorn"
                      style={{ display: "block" }}
                      onClick={() => setShowToolTip(!showToolTip)}
                    />
                    {showToolTip && (
                      <ClickAwayListener
                        onClickAway={() => setShowToolTip(false)}
                      >
                        <div className="tooltip" id="tooltip">
                          {tooltipMessage}
                        </div>
                      </ClickAwayListener>
                    )}
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={Number(rewards)}
                      onChange={handlePnl}
                      ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <p>{asset}</p>
                      </span>
                    </span>
                  </div>
                </MiniAmountBoxFull>

                <MiniAmountBoxFull
                  className={
                    amountError ? "amount_box error" : "amount_box variant"
                  }
                >
                  <div className="label">
                    <p>Duration</p>
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={Number(duration)}
                      onChange={handleDuration}
                      //   ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <p style={{ textTransform: "uppercase" }}>
                          {duration === 1 ? "day" : "days"}
                        </p>
                      </span>
                    </span>
                  </div>
                </MiniAmountBoxFull>

                <MiniAmountBoxFull
                  className={
                    amountError ? "amount_box error" : "amount_box variant"
                  }
                >
                  <div className="label">
                    <p>Days Left</p>
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={Number(details?.daysLeft)}
                      onChange={handleDaysLeft}
                      //   ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <p style={{ textTransform: "uppercase" }}>
                          {numDaysLeft === 1 ? "day" : "days"}
                        </p>
                      </span>
                    </span>
                  </div>
                </MiniAmountBoxFull>

                <TextBox className="scrollbar-hide">
                  <label htmlFor="address">Status:</label>
                  <br />
                  <textarea
                    type="text"
                    cols="10"
                    rows="2"
                    ref={statusRef}
                    placeholder={status}
                    onChange={handleStatus}
                  ></textarea>
                </TextBox>

                <div className="details_wrapper">
                  <span className="details">
                    <p className="title">ROI</p>
                    <p className="detail">{roi}%</p>
                  </span>

                  <span className="details">
                    <p className="title">Current rewards</p>
                    <p className="detail">
                      {rewards} {asset}
                    </p>
                  </span>
                </div>

                {/* <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      maxWidth: "180px",
                      width: "100%",
                      position: "relative",
                    }}
                  >
                    {showCompiledToolTip && (
                      <ClickAwayListener
                        onClickAway={() =>
                          setShowCompiledToolTip(!showCompiledToolTip)
                        }
                      >
                        <ToolTipContainer>
                          <div
                            className="tooltip"
                            id="tooltip"
                            style={{
                              bottom: "28px",
                              left: "5px",
                              width: "100%",
                            }}
                          >
                            {compiledToolTipMessage}
                          </div>
                        </ToolTipContainer>
                      </ClickAwayListener>
                    )}
                    <p
                      style={{
                        color: "#bac2de",
                        fontSize: "14px",
                        lineHeight: "20px",
                      }}
                    >
                      Total returns after staking closes
                      <span style={{ position: "absolute", marginLeft: "4px" }}>
                        <img
                          src="./assets/misc/info.svg"
                          alt=""
                          className="error_inform"
                          id="popcorn"
                          onClick={() =>
                            setShowCompiledToolTip(!showCompiledToolTip)
                          }
                        />
                      </span>
                    </p>
                  </span>
                  <span>
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#5BDE4C",
                        fontWeight: "600",
                      }}
                    >
                      {Number(computedPnl) + amount} {asset}
                    </p>
                  </span>
                </div> */}
              </LargeDivider>
            </div>

            <div className="bottom">
              <FullButton
                onClick={handleSave}
                className={isSaving && "disabled"}
              >
                {isSaving ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FullButton>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .details_wrapper {
    padding: 24px 0px;
    padding-top: 12px;
  }

  .details {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
  }

  .title {
    color: #bac2de;
  }

  .detail {
    color: white;
  }

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }

  .bottom button:hover {
    background-color: #ff3344;
  }

  .bottom {
    margin-top: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-y: scroll;

    .bottom button {
      margin: 0;
    }

    .top {
      margin-bottom: 52px;
    }

    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }
`;

// const SubscriptionCardStandard = styled.div`
//   background-color: #151823;
//   height: 100;
//   border-radius: 12px;
// `;

export default ManageStakingModal;

// outlinedModal
// filledModal
