import { styled } from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  width: 100%;

  @media screen and (max-width: 1000px) {
    grid-template-columns: auto;
  }
`;
