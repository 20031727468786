import { styled } from "styled-components";

export const WatchlistStar = styled.span`
  align-self: center;
  height: fit-content;
  padding: 0;
  margin: 0;
  display: grid;
  place-content: center;
  cursor: pointer;

  svg path {
    fill: none;
    stroke: #98a1b2;
    stroke-width: 3;
    transition: all 0.1s ease-in-out;
  }

  &:hover svg path {
    stroke: #cedaf0;
  }

  &.pressed svg path {
    stroke: #ffb266;
    fill: #ffb266;
  }

  &.variant svg path {
    stroke: none;
    fill: #98a1b2;
  }

  &.filled svg path {
    fill: #98a1b2;
    stroke: none;
  }

  &.switcher svg path {
    fill: #98a1b2;
    stroke: none;
  }

  &.switcher.active svg path {
    fill: #98a1b2;
    stroke: none;
    fill: #0c6cf2;
  }
`;
