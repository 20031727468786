import { useContext, useEffect, useRef, useState } from "react";
import Modal from "@mui/material/Modal";
import { styled } from "styled-components";
import { ClickAwayListener } from "@mui/material";
import { LargeDivider } from "../styled/forms/dividers";
import {
  AmountBox,
  DropDownBox,
  FullButton,
  MiniAmountBox,
  MiniAmountBoxFull,
  TextBox,
  ToolTipContainer,
} from "../styled/input/Input";

import {
  doc,
  increment,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "@firebase/firestore";
import { db } from "../firebase/firebase";
import CircularLoader from "../styled/loaders/CircularLoader";
import Toast from "../hooks/Toast";

const ManageWithdrawalModal = ({ open, info, options, prices }) => {
  const { manageWithdrawal, setManageWithdrawal } = open;

  const { ref, asset, amount, details, type, status } = info;

  // toast
  const [openToast, setOpenToast] = useState(false);
  const [toastMessage, setToastMessage] = useState(" ");
  const [toastType, setToastType] = useState(undefined);

  //   const { id } = user;

  //   useEffect(() => {
  //     if (prices && accounts) {
  //       const live = accounts.live;
  //       if (live) {
  //         setCryptoAccount(live.Crypto);
  //         setBalance(live.Crypto[asset]?.value);
  //       }
  //     }
  //   }, [prices, accounts]);

  // amount
  const amountRef = useRef();
  //   const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);

  const [showToolTip, setShowToolTip] = useState(false);
  const [tooltipMessage, setToolTipMessage] = useState(
    "Using negative sign will reduce from the current rewards, not using any sign will add to the rewards. Please do not remove more than the current amount"
  );

  const [computedPnl, setComputedPnl] = useState(0);

  //   asset
  const [selectedAsset, setSelectedAsset] = useState(asset);

  // amount
  const [selAmount, setSelAmount] = useState(amount);
  function handleAmount(e) {
    const { value } = e.target;

    if (value) {
      setSelAmount(Number(value));
    } else {
      setSelAmount(amount);
    }
  }

  // details
  const [selDetails, setSelDetails] = useState(details);
  function handleDetails(e) {
    const { value } = e.target;

    if (value) {
      setSelDetails(value);
    } else {
      setSelDetails(details);
    }
  }

  //   type
  const [selectedWithdrawalOption, setSelectedWithdrawalOption] =
    useState(type);

  // status
  const [selStatus, setSelStatus] = useState(status);
  function handleStatus(e) {
    const { value } = e.target;

    if (value) {
      setSelStatus(value);
    } else {
      setSelStatus(status);
    }
  }

  // roi & compiled
  const [compiledTotal, setCompiledTotal] = useState(0);
  const [showCompiledToolTip, setShowCompiledToolTip] = useState(false);
  const compiledToolTipMessage = useState(
    `This is the amount the user will earn after the staking is complete (plus their initial stake)`
  );

  const [isSaving, setIsSaving] = useState(false);
  function handleSave() {
    setIsSaving(true);
    saveWithdrawal();
  }

  // assets
  const assetsList = [
    {
      asset: "BTC",

      name: "Bitcoin",
    },
    {
      asset: "USD",
      name: "United States Dollars",
    },
    {
      asset: "CAW",
      name: "A Hunter's Dream",
    },
    {
      asset: "ETH",
      name: "Ethereum",
    },
    {
      asset: "SOL",
      name: "Solana",
    },
    {
      asset: "BCH",
      name: "Bitcoin Cash",
    },
    {
      asset: "LTC",
      name: "Litecoin",
    },
    {
      asset: "DOGE",
      name: "Dogecoin",
    },
    {
      asset: "USDT",

      name: "Tether",
    },
    {
      asset: "MATIC",

      name: "Polygon",
    },
    {
      asset: "AVAX",

      name: "Avalanche",
    },
    {
      asset: "USDC",

      name: "USD Coin",
    },
    {
      asset: "AAVE",

      name: "AAVE",
    },
    {
      asset: "ALGO",

      name: "Algorand",
    },
    {
      asset: "ANC",

      name: "Anchor Protocol",
    },
    {
      asset: "APE",

      name: "ApeCoin",
    },
    {
      asset: "AURORA",

      name: "Aurora",
    },
    {
      asset: "AXS",

      name: "Axie Infinity",
    },
    {
      asset: "BTG",

      name: "Bitcoin Gold",
    },
    {
      asset: "BORING",

      name: "Boring DAO",
    },
    {
      asset: "ADA",

      name: "Cardano",
    },
    {
      asset: "XCN",

      name: "Onyxcoin",
    },
    {
      asset: "LINK",

      name: "ChainLink",
    },

    {
      asset: "CRO",

      name: "Cronos",
    },
    {
      asset: "DAI",

      name: "Dai",
    },
    {
      asset: "DASH",

      name: "Dash",
    },
    {
      asset: "MANA",

      name: "Decentraland",
    },

    {
      asset: "ETC",
      name: "Ethereum Classic",
    },
    {
      asset: "EVMOS",
      name: "Evmos",
    },
    {
      asset: "GT",
      name: "Gate Token",
    },
    {
      asset: "LN",
      name: "Link",
    },
    {
      asset: "XMR",
      name: "Monero",
    },
    {
      asset: "NEXO",
      name: "Nexo",
    },
    {
      asset: "OKB",
      name: "OKB",
    },
    {
      asset: "OP",
      name: "Optimism",
    },
    {
      asset: "OGN",
      name: "Origin Protocol",
    },
    {
      asset: "ORN",

      name: "Orion Protocol",
    },
    {
      asset: "DOT",
      name: "Polkadot",
    },
    {
      asset: "XPR",

      name: "Proton",
    },

    {
      asset: "RARI",

      name: "Rarible",
    },
    {
      asset: "SFP",

      name: "Safepal",
    },
    {
      asset: "SHIB",

      name: "Shiba Inu",
    },
    {
      asset: "XLM",

      name: "Stellar",
    },

    {
      asset: "GMT",

      name: "Stepn",
    },
    {
      asset: "SUSHI",
      name: "Sushi",
    },
    {
      asset: "TLOS",

      name: "Telos",
    },
    {
      asset: "XTZ",

      name: "Tezos",
    },
    {
      asset: "GRT",

      name: "The Graph",
    },
    {
      asset: "TRX",

      name: "Tron",
    },
    {
      asset: "UNI",
      name: "Uniswap",
    },
    {
      asset: "VET",

      name: "Vechain",
    },
    {
      asset: "WING",

      name: "Wing Finance",
      type: "Crypto",
    },

    {
      asset: "ZEC",

      name: "Zcash",
    },

    {
      asset: "XRP",

      name: "Ripple",
    },
  ].sort();

  async function saveWithdrawal() {
    console.log(selectedAsset === "USD");

    const q = doc(db, "withdrawals", ref);
    try {
      await updateDoc(q, {
        asset: selectedAsset,
        amount: Number(selAmount),
        details: selDetails,
        type: selectedWithdrawalOption,
        status: selStatus,
        totalInUSD:
          selectedAsset === "USD"
            ? Number(selAmount)
            : prices[selectedAsset] * Number(selAmount),
      });
      setIsSaving(false);
      setToastType("success");
      setToastMessage("Saved successfully");
      setOpenToast(true);
      setTimeout(() => {
        setManageWithdrawal(false);
      }, 700);
    } catch (error) {
      console.log("error", error);
      setIsSaving(false);
      setToastType("error");
      setToastMessage("Failed to save. Please try again later.");
      setOpenToast(true);
    }
  }

  return (
    <>
      {openToast && (
        <Toast
          open={{ openToast, setOpenToast }}
          message={toastMessage}
          type={toastType}
        />
      )}

      <Modal
        open={manageWithdrawal}
        onClose={() => setManageWithdrawal(false)}
        style={{
          display: "flex",
          placeContent: "center",
          zIndex: "10001",
        }}
      >
        <ModalStandard className="scrollbar-hide">
          <div className="modal_top">
            <p>Manage withdrawal</p>

            <svg
              width="15"
              height="14"
              viewBox="0 0 15 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setManageWithdrawal(!manageWithdrawal)}
              style={{ cursor: "pointer" }}
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.8647 0.366365C12.3532 -0.122122 13.1451 -0.122122 13.6336 0.366365C14.1221 0.854853 14.1221 1.64685 13.6336 2.13533L8.88929 6.87968L13.8743 11.8647C14.3628 12.3532 14.3628 13.1451 13.8743 13.6336C13.3858 14.1221 12.5938 14.1221 12.1053 13.6336L7.12032 8.64864L2.13533 13.6336C1.64685 14.1221 0.854853 14.1221 0.366366 13.6336C-0.122122 13.1451 -0.122122 12.3532 0.366366 11.8647L5.35136 6.87968L0.607014 2.13533C0.118527 1.64685 0.118527 0.854853 0.607014 0.366365C1.0955 -0.122122 1.8875 -0.122122 2.37598 0.366365L7.12032 5.11071L11.8647 0.366365Z"
                fill="#858DAD"
              />
            </svg>
          </div>

          <div className="modal_content">
            <div className="top">
              <LargeDivider className="variant">
                <MiniAmountBoxFull
                  className={
                    amountError ? "amount_box error" : "amount_box variant"
                  }
                >
                  <div className="label">
                    <p>Withdrawal amount</p>
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={amount}
                      //   disabled
                      onChange={handleAmount}
                      ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <p>{selectedAsset}</p>
                      </span>
                    </span>
                  </div>
                </MiniAmountBoxFull>

                <DropDownBox className="type_select">
                  <div className="wrapper">
                    <p className="label">Type: {type}</p>
                    <span className="content">
                      <select
                        name="options"
                        onChange={(e) =>
                          setSelectedWithdrawalOption(e.target.value)
                        }
                      >
                        {options.map((option) => (
                          <option value={option} key={option}>
                            {option}
                          </option>
                        ))}
                      </select>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 9L12 15L18 9"
                          stroke="#5C6175"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </DropDownBox>

                <DropDownBox className="type_select">
                  <div className="wrapper">
                    <p className="label">Asset: {asset}</p>
                    <span className="content">
                      <select
                        name="options"
                        onChange={(e) => setSelectedAsset(e.target.value)}
                      >
                        {assetsList.map((option) => (
                          <option value={option.asset} key={option.asset}>
                            {option.asset}
                          </option>
                        ))}
                      </select>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6 9L12 15L18 9"
                          stroke="#5C6175"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </DropDownBox>

                {/* <MiniAmountBoxFull
                  className={
                    amountError ? "amount_box error" : "amount_box variant"
                  }
                >
                  <div className="label">
                    <p>Duration</p>
                  </div>

                  <div className="wrapper">
                    <input
                      type="number"
                      placeholder={Number(duration)}
                      onChange={handleDuration}
                      //   ref={amountRef}
                    />

                    <span className="asset">
                      <span>
                        <p style={{ textTransform: "uppercase" }}>
                          {duration === 1 ? "day" : "days"}
                        </p>
                      </span>
                    </span>
                  </div>
                </MiniAmountBoxFull> */}

                <TextBox className="scrollbar-hide">
                  <label htmlFor="address">Details:</label>
                  <br />
                  <textarea
                    type="text"
                    cols="10"
                    rows="2"
                    // ref={statusRef}
                    placeholder={details}
                    onChange={handleDetails}
                  ></textarea>
                </TextBox>

                <TextBox className="scrollbar-hide">
                  <label htmlFor="address">Status:</label>
                  <br />
                  <textarea
                    type="text"
                    cols="10"
                    rows="2"
                    // ref={statusRef}
                    placeholder={status}
                    onChange={handleStatus}
                  ></textarea>
                </TextBox>
              </LargeDivider>
            </div>

            <div className="bottom">
              <FullButton
                onClick={handleSave}
                className={isSaving && "disabled"}
              >
                {isSaving ? (
                  <div style={{ padding: "8px" }}>
                    <CircularLoader bg="#cccccc" size="28" color="#ffffff" />
                  </div>
                ) : (
                  <p>Save</p>
                )}
              </FullButton>
            </div>
          </div>
        </ModalStandard>
      </Modal>
    </>
  );
};

const ModalStandard = styled.div`
  background-color: #151823;
  border-radius: 12px;
  max-width: 430px;
  place-self: center;
  width: 100%;
  border: 1px solid transparent;
  z-index: 10001;

  .details_wrapper {
    padding: 24px 0px;
    padding-top: 12px;
  }

  .details {
    margin-top: 12px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    font-weight: 500;
  }

  .title {
    color: #bac2de;
  }

  .detail {
    color: white;
  }

  .bottom button {
    cursor: pointer;
    width: 100%;
    background-color: #0c6cf2;
    padding: 12px;
    color: white;
    font-size: 14px;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
  }

  .bottom button:hover {
    background-color: #ff3344;
  }

  .bottom {
    margin-top: 32px;
  }

  @media screen and (max-width: 768px) {
    width: 100vw;
    max-width: unset;
    height: fit-content;
    max-height: 90vh;
    position: fixed;
    left: 0;
    bottom: 0;
    padding-bottom: 48px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    overflow-y: scroll;

    .bottom button {
      margin: 0;
    }

    .top {
      margin-bottom: 52px;
    }

    .bottom {
      position: fixed;
      bottom: 0px;
      right: 0px;
      width: 100%;
      padding: 12px 24px;
      height: fit-content;
      background-color: #151823;
      z-index: 999;
      border: none;
      outline: none;
      /* display: none; */
    }
  }

  .modal_top {
    color: white;
    font-size: 16px;
    font-weight: 600;
    background-color: #1b1f2d;
    width: 100%;
    padding: 14px 30px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    position: sticky;
    top: 0;
    z-index: 999;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .modal_content {
    padding: 24px;
  }

  .modal_content .top {
    display: grid;
    gap: 24px;
  }
`;

// const SubscriptionCardStandard = styled.div`
//   background-color: #151823;
//   height: 100;
//   border-radius: 12px;
// `;

export default ManageWithdrawalModal;

// outlinedModal
// filledModal
